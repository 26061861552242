import * as React from "react";
import { BaseLayout, OuterContainer } from "../components/Layout";
import { graphql } from "gatsby";
import { ContactForm } from "../components/Common";
import {
  FeaturesCards,
  InfoSection,
  Testimonials,
} from "../components/Sections";

const IndexPage = ({ data }) => {
  return (
    <BaseLayout page="index">
      <InfoSection cardData={data.allInfoTextJson.edges} />
      <OuterContainer>
        <FeaturesCards />
      </OuterContainer>

      <OuterContainer reversed>
        <ContactForm />
      </OuterContainer>
    </BaseLayout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allInfoTextJson {
      edges {
        node {
          heading
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 95
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                aspectRatio: 1
              )
            }
          }
          link
          linkText
          reversed
          text
        }
      }
    }
  }
`;
